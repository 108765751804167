
import "./main.css";
import "./styleListfinal.css";
import LandingPage from "./components/screens/landingPage/landingPage";
import { Routes, Route } from "react-router-dom";
import data from "./utils/LanguagesCountries";
import StateOutlet from "./router/outlets/langFaOut";
import iso2 from "./utils/iso2.json"
import NotFound from "./components/persian/common/alerts/404";
import FaRoutes from "./router/FaRoutes";
import ComingSoon from "./components/persian/common/alerts/comingSoon";
import CountryRoutes from "./router/countryRoutes";
import { useEffect } from "react";
import countryStates from "./utils/countryStates";
import api from "./utils/api";
import EnRoutes from "./router/EnRoutes";
import LangFaOut from "./router/outlets/langFaOut";
import LangEnOut from "./router/outlets/langEnOut";

function App() {
  // const navigate = useNavigate();  
  const lang = window.location.pathname.split("/")[1];
  const pathname = window.location.pathname.toLowerCase().split("/").slice(1, 4);
  const languagesSet = new Set(data.languages);  
  const matchedLang = languagesSet.has(pathname[0]) ? pathname[0] : null;
  const countIndex = iso2.findIndex(count=> count.iso2.toLowerCase() === pathname[1])
  const matchedCountry =countIndex!==-1? iso2[countIndex] : null;
  // const matchedState=matchedCountry?.states.find(state=> state.toLowerCase()===pathname[2]) 
useEffect(()=>{ 
  affiliateSet()
  // getGeoInfo()
  // setInitSetting()
  // updateBootstrapLink()
  initAppSetup();
},[lang])

async function initAppSetup() {
  const item = JSON.parse(localStorage.getItem('initSetup'));
  
  if (!item || item === 'undefined') {
    await fetchGeoInfo(); // If no setup, fetch Geo info
  } else {
    applyInitSetting(item); // If setup exists, apply it
  }
}

async function fetchGeoInfo() {
  try {
    const response = await api.getGeoInfo();
    
    if (response.error) {
      console.error(response.error);
      return;
    }

    const currentCountry = countryStates.find(co => co.countryShort === response?.country);
    const currentState = currentCountry?.states.find(st => st.state === response?.region);

    const [lat, lng] = response.loc.split(",");

    const initSetup = {
      lang: lang || "fa",
      country: currentCountry?.country?.toLowerCase() || "united States",
      countryShort: response?.country?.toLowerCase() || "us",
      city: response?.city || 'Los Angeles',
      state: {
        state: response?.region?.toLowerCase() || 'California',
        stateShort: currentState?.stateShort || "ca",
        lat: lat || "52.93991590",
        lng: lng || "-73.54913610",
      },
      firstRender: false,
    };

    localStorage.setItem("initSetup", JSON.stringify(initSetup));
    console.log('Geo setup applied:', initSetup);
  } catch (error) {
    console.error('Error fetching geo info:', error);
  }
}

function applyInitSetting(item) {
  const initSetup = {
    lang: lang || "fa",
    country: item?.country?.toLowerCase() || "ca",
    countryShort: item?.countryShort?.toLowerCase() || "canada",
    city: item?.city || "",
    state: item?.state || {
      state: 'quebec',
      stateShort: "qc",
      lat: "52.93991590",
      lng: "-73.54913610",
    },
    firstRender: false,
  };

  localStorage.setItem("initSetup", JSON.stringify(initSetup));
  console.log('Local setup applied:', initSetup);
}

    function affiliateSet(){
      const urlParams  = new URLSearchParams(window.location.search)
      const affiliateCode = urlParams.get("aff")
      const ttl = 7*24*60*60*1000
      console.log('affiliateCode',affiliateCode)
      if(affiliateCode?.length>=10) {
        const now = new Date();
  
        const item = {
          affCode: affiliateCode,
          expiry: now.getTime() + ttl,
      };
      localStorage.setItem('affiliate', JSON.stringify(item));
        // localStorage.setItem("affiliate",affiliateCode)
      }
    }

return (
    <>
      <Routes>          
      {/* <Route path={"/"+matchedLang+"/"+matchedCountry?.iso2+"/"+matchedState} element={<StateOutlet/>} > */}
      <Route path={"/"+matchedLang+"/"+matchedCountry?.iso2} element={<StateOutlet/>} >
      {CountryRoutes()}   
      </Route>
      <Route path={"/fa"} element={<LangFaOut/>} >
        {FaRoutes()}       
      </Route>
      <Route path={"/en"} element={<LangEnOut/>} >
        {EnRoutes()}       
      </Route>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/denied" element={<AccessDenied />} />
        <Route path="/privacy" element={<Privacy />} /> */}
        <Route path="/coming" element={<ComingSoon />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );

}

export default App;
