import { Navigate, Route } from "react-router-dom";
import Home from "../components/persian/home/home";
import BusinessOut from "./outlets/businessOut";
import { useContext } from "react";
import { CartContext } from "../components/contexts/cartContext";
// import JobsOut from "./outlets/jobsOut";
// import MediaOut from "./outlets/MediaOut";
// import EventOut from "./outlets/eventOut";
// import DemyOut from "./outlets/demyOut";
// import MarketPlaceOut from "./outlets/MarketPlaceOut";
// import BusinessHome from "../components/screens/business/home/businessHome";
// import MarketPlaceHome from "../components/screens/marketPlace/home/marketPlaceHome";
// import EventHome from "../components/screens/event/eventHome";
// import DemyHome from "../components/screens/demy/demyHome";
// import DemyTutorHome from "../components/screens/demy/demyTutorHome";
// import JobsHome from "../components/screens/jobs/jobsHome";
// import MediaHome from "../components/screens/media/mediaHome";
import BusinessHomeCountry from "../components/screens/business/home/businessHomeCountry";



export default function CountryRoutes() {
  const lcs = window.location.pathname.split("/").slice(1, 4).join("/");
  const { user } = useContext(CartContext); 
  return (
    <>
      <Route path="" element={<Home />} />
      <Route path="business" element={<BusinessOut />}>
        <Route path="" element={<BusinessHomeCountry />} />
      </Route>
      {/* <Route path="mp" element={<MarketPlaceOut />}>
        <Route path="" element={<MarketPlaceHome />} /> */}
        {/* {MarketPlaceRoutes()} */}
      {/* </Route> */}
      {/* <Route path="event"element={user?.roles?.includes(1000) ? <EventOut />: <Navigate replace to={`/${lcs}`} />}>
        <Route path="" element={<EventHome />} /> */}
        {/* {eventRoutes()} */}
      {/* </Route> */}
      
      {/* <Route path="demy"element={<DemyOut />}>
      <Route path="" element={<DemyHome />} />
      <Route path="tutor/home" element={<DemyTutorHome />} /> */}
        {/* {demyRoutes()} */}
      {/* </Route>       */}
      {/* <Route path="jobs" element={<JobsOut />}>
          <Route path="" element={<JobsHome />} /> */}
        {/* {jobsRoutes()} */}
      {/* </Route> */}
      {/* <Route path="media" element={<MediaOut />}>
        <Route path="" element={<MediaHome />} /> */}
        {/* {mediaRoutes()} */}
      {/* </Route>     */}
    </>
  );
}
